import { PureComponent } from 'react';
import { withTrans } from '@shares/locales/hoc';
import AuthInfoBox from '@shares/auth-info-box';
import { UserProfileBoxProps } from './interface';
import { ContextType } from '@ui/components/context';

import './style.scss';

class UserProfileBox extends PureComponent<UserProfileBoxProps> {
  static contextType = ContextType;

  componentDidMount() {
    this.context.refreshAuth();
  }
  render() {
    const { prefix, isError } = this.props;
    const consumer = {
      first_name: this.context.auth.consumer.firstName,
      last_name: this.context.auth.consumer.lastName,
      phone_number: this.context.auth.consumer.phoneNumber,
      email: this.context.auth.consumer.email
    };
    return (
      <div className="profile p-3 p-lg-0">
        <h2>แก้ไขข้อมูลส่วนตัว</h2>
        <p className="profile__description">
          ข้อมูลนี้จะถูกใช้เพื่อป้อนรายละเอียดของคุณโดยอัตโนมัติเพื่อให้คุณสามารถทำการจองได้อย่างรวดเร็ว
          รายละเอียดของคุณจะได้รับการจัดเก็บไว้อย่างปลอดภัยและจะไม่ถูกเผยแพร่ต่อสาธารณะ
        </p>
        <div>
          <AuthInfoBox
            prefix={prefix}
            toggleAlertBox={this.props.toggleAlertBox}
            initailFormValues={consumer}
            isError={isError}
            enableConfirmEmailModal
            infoFetching={this.context.auth.fetching}
          />
        </div>
      </div>
    );
  }
}

export default withTrans('auth.profile')(UserProfileBox);
